(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .factory('stateHandler', stateHandler);

    stateHandler.$inject = ['$rootScope', '$state', '$sessionStorage',  '$window',
        'Auth', 'Principal', 'VERSION', '$anchorScroll', '$location'];

    function stateHandler($rootScope, $state, $sessionStorage,  $window,
        Auth, Principal, VERSION, $anchorScroll, $location) {
        return {
            initialize: initialize
        };

        function initialize() {
            $rootScope.VERSION = VERSION;

            var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams, fromState) {
                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;
                $rootScope.fromState = fromState;

                // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
                if (toState.external) {
                    event.preventDefault();
                    $window.open(toState.url, '_self');
                }

                if (Principal.isIdentityResolved()) {
                    Auth.authorize();
                }

                //If user trying to go on login page.
                if(toState.name ==='login' && Principal.isAuthenticated()){
                    //demo.showNotification("You are already logged in", 'info', 'top','center');
                    $state.go(fromState.name !== '' ? fromState.name :'home');
                }

                if((toState.name ==='register' || toState.name==='forgot-password') && Principal.isAuthenticated()){
                    Auth.logout();
                }


            });

            var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess',  function(event, toState, toParams, fromState, fromParams) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;

                if(document.getElementById('navbar_main')){
                    document.getElementById('navbar_main').scrollIntoView();
                }

                var titleKey = 'UZED Business' ;
                if(['basket', 'checkout'].indexOf(toState.name) >= 0){
                    if(!$rootScope.activeOrder && $rootScope.cart.total <=0){
                        $state.go('home');
                    }
                }

                // Set the page title key to the one configured in state or use default one
                if (toState.data.pageTitle) {
                    titleKey = toState.data.pageTitle;
                }
                $window.document.title = titleKey;
            });

            $rootScope.$on('$destroy', function () {
                if(angular.isDefined(stateChangeStart) && stateChangeStart !== null){
                    stateChangeStart();
                }
                if(angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null){
                    stateChangeSuccess();
                }
            });
        }
    }
})();
