(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .factory('Order', OrderService);

    OrderService.$inject = ['$resource'];

    /*== ORDER API ==*/
    function OrderService($resource) {
        var resourceUrl = 'api/customer/orders/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    //data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                    //data.pickupTime = DateUtils.convertDateTimeFromServer(data.pickupTime);
                    return data;
                }
            },
            'update': {method: 'PUT', params: {id: "@id"}},
            'create': {method: 'POST'},
            'activeOrder': {method: 'GET', url: 'api/customer/active-order'},
            'confirmPickup': {method: 'POST', url: 'api/customer/active-order/confirm-pickup'},
            'denyPickup': {method: 'POST', url: 'api/customer/active-order/deny-pickup'},
            'cancelPickup': {
                method: 'POST',
                url: 'api/customer/cancelOrder',
                params: {orderId: '@orderId', reason: '@reason'},
                showLoading: true
            }
        });
    }


})();
