(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .controller('CheckoutController', CheckoutController);


    CheckoutController.$inject = ['$scope', 'ErrorExtractor', '$timeout', 'AlertService', 'CurrentUser', 'Cart', '$rootScope', 'TimeSlot', 'Order', '$state'];

    function CheckoutController ($scope, ErrorExtractor, $timeout, AlertService, CurrentUser, Cart, $rootScope, TimeSlot, Order, $state) {
        var vm = this;
        vm.addr = {};
        var oldAddress  = "";
        //delay click
        $timeout(function () {
            vm.enableStreetField = true;
        }, 300);

        vm.options = {types: 'establishment',
            country: 'in'
        };
        var activeOrder = $rootScope.activeOrder;

        vm.fillAddr = function () {
            //if(!window.local) return;
            vm.addr = {
                street: "76, Hitech City Main Road, Silpa Gram Craft Village, HITEC City",
                flatBuilding: 'Janardhan Plaza',
                landmark: 'Near Ero School',
                city: "Hyderabad",
                zip: "500081",
                mobile: "1234567890"
            };
            var ts = _.find(timeSlots, {available: true});
            initializing = true;
            vm.schedule.pickupDay = withoutTime(ts.startTime).getTime().toString();
            setDaySlots();
            vm.schedule.timeSlotId = ts.id.toString();
        };
        var withoutTime = function (d) {
            d = new Date(d);
            var localTZOffset = d.getTimezoneOffset();
            var modTime = d.getTime() + ((localTZOffset + 330) * 60 * 1000);
            d = new Date(modTime);
            d.setHours(0, 0, 0, 0, 0);
            return d;
        };

        function sanitizeStreetAddress(addr) {
            var searchRegex = new RegExp(", " + addr.city + ".*");
            return addr.street.replace(searchRegex, '');
        }

        vm.fullAddr = function () {
            if (vm.addr.street) {
                return vm.addr.street + ", " +
                    (vm.addr.city ? vm.addr.city + " " : "") +
                    (vm.addr.state ? vm.addr.state + " " : "") +
                    (vm.addr.zip ? vm.addr.zip : "");
            }
            return "";
        };

        function refresh() {
            CurrentUser.refresh().then(function (currentUser) {
                vm.addr = $rootScope.activeOrder ? $rootScope.activeOrder.contactDetails :
                    currentUser.lastOrder ? angular.copy(currentUser.lastOrder.contactDetails) :
                        {street: "", city: "", zip: "", mobile: currentUser.mobile};
                vm.addr.mobile = currentUser.mobile;
                vm.currentUser = currentUser.currentUser;
                //Fetch latlng if LatLng Null in lastOrder
                if(!vm.addr.latLng){
                    console.log("Latlng Check", vm.addr.latLng);
                    oldAddress = vm.fullAddr(vm.addr);
                }

                if (angular.isDefined($rootScope.data)) {
                    vm.addr.street = sanitizeStreetAddress($rootScope.data);
                    vm.addr.city = $rootScope.data.city;
                    vm.addr.state = $rootScope.data.state;
                    vm.addr.zip = $rootScope.data.postalCode;
                    vm.addr.latLng = $rootScope.data.latLng;
                    console.log("vm.addr = " + JSON.stringify(vm.addr));
                }

                if (vm.addr.zip && vm.addr.zip !== '') {
                    vm.getTimeSlotsByZipCode();
                }else{
                    clearTimeSlots();
                }

            });
        }
        refresh();


        clearTimeSlots();

        var timeSlots;
        var dayWiseSlots;

        vm.schedule.pickupDay = vm.schedule.timeSlotId = "";
        function init(_timeSlots) {
            vm.error = false;
            timeSlots = _timeSlots;

            //TODO: add active order's pickup time to timeSlots if not present.
            timeSlots.forEach(function (ts) {
                ts.day = withoutTime(ts.startTime).getTime();
            });

            vm.days = _.uniq(timeSlots.map(function (ts) {
                return ts.day;
            }), true);
            dayWiseSlots = _.groupBy(timeSlots, "day");
            vm.days = _.map(vm.days, function (day) {
                return {day: day, available: _.filter(dayWiseSlots[day], "available").length};
            });

            if (activeOrder && activeOrder.timeSlot) {
                initializing = true;
                vm.schedule.pickupDay = withoutTime(activeOrder.timeSlot.startTime).getTime().toString();
                //@selectedDatePosition: auto scroll datepicker to selected date
                vm.selectedDatePosition = _.findIndex(vm.days, {day : _.toNumber(vm.schedule.pickupDay)});
                setDaySlots();
                // vm.schedule.timeSlotId = activeOrder.timeSlot.id.toString();
                vm.schedule.timeSlotId = _.toString(_.get(_.find(vm.daySlots, {startTime:activeOrder.timeSlot.startTime}),'id'));
            } else {
                vm.schedule.pickupDay = getNextAvailableDay().day;
                vm.selectedDatePosition = _.findIndex(vm.days, {day : _.toNumber( vm.schedule.pickupDay)});
                initializing = false;

            }
            $scope.$watch("vm.schedule.pickupDay", onDayChange);
            $scope.$watch("vm.schedule.timeSlotId", onTimeSlotChange);

        }

        // TimeSlot.listAvailable().$promise.then(init);
        vm.getTimeSlotsByZipCode = function () {
            var lat = angular.isUndefined(vm.addr.latLng) ? null : vm.addr.latLng.lat;
            var lng = angular.isUndefined(vm.addr.latLng) ? null : vm.addr.latLng.lng;
            vm.timeslotLoading = true;
            TimeSlot.listAvailable({zip: vm.addr.zip, lat: lat, lng: lng}).$promise
                .then(function (_timeSlots) {
                    vm.timeslotLoading = false;
                    init(_timeSlots);
                })
                .catch(function (res) {
                    vm.timeslotLoading = false;
                    clearTimeSlots();
                    vm.error = ErrorExtractor(res);
                    AlertService.error(vm.error.message);
                });
        };


        function setDaySlots() {

            vm.daySlots = dayWiseSlots[vm.schedule.pickupDay];
            console.log("setDaySlots", JSON.stringify(vm.daySlots));

        }

        var initializing = true;

        function onDayChange() {
            if (initializing) {
                initializing = false;
                return;
            }
            setDaySlots();
            vm.schedule.timeSlotId = "";
        }

        function onTimeSlotChange() {
            vm.selectedTimeSlot = _.find(vm.daySlots, function (slot) {
                return slot.id == vm.schedule.timeSlotId;
            });
            console.log(vm.selectedTimeSlot);
            if(activeOrder) {
                activeOrder.timeSlot = vm.selectedTimeSlot;
            }
        }


        vm.oldZip = "";

        //set old zip for check
        vm.setOldZip = function () {
            vm.oldZip = vm.addr.zip;
        };

        //update timeslot if zipcode is changed
        vm.checkZipCodeChange = function () {
            clearTimeSlots();
            if(vm.addr.zip && vm.addr.zip.length === 6 && !angular.equals(vm.addr.zip, vm.oldZip)){
                vm.getTimeSlotsByZipCode();
            }
        };

        vm.isZipCodePresent = function ($event) {
            //console.log("event = " + JSON.stringify(event));
            if (!vm.addr.zip) {
                navigator.notification.alert("Please locate on map first.", null, "");
                //$ionicPopup.alert({template:"<div class='single-line-popup'>Please enter the address first.</div>", okType: "popup-bottom-button"});
                $event.preventDefault();
                $event.target.blur();
                window.focus();
            }
            return false;
        };

        vm.placeOrder = function (form) {
            //Get location
            form.$setSubmitted();
            if (!form.$valid) {
                demo.showNotification("Please fill all mandatory details", 'danger');
                return;
            }

            var selectedTimeSlot = _.find(timeSlots, {id: parseInt(vm.schedule.timeSlotId)});
            var order = {
                orderItems: Cart.cartItems(),
                contactDetails: vm.addr,
                timeSlot: selectedTimeSlot
            };

            if(Cart.igProduct){
                order.gift = {id:Cart.igProduct.id};
            }

            console.log("$rootScope.LatLng:", $rootScope.LatLng);
            return Order.create(order).$promise.then(function (order) {
                // Tracker.trackEvent("Purchased", {amount:Cart.total, itemCount:Cart.orderItemCount});
                //deregisterAbandonedMonitor();
                Cart.empty();
                Cart.save();
                vm.order = order;
                $scope.$emit("needRefresh");
                swal({
                    title: "Pickup scheduled Successfully!",
                    text: "",
                    type:'success'
                });
                $state.go('home');
            }).catch(function (response) {
                var error = ErrorExtractor(response);
                AlertService.error(error.message);
            });
        };

        //TODO: should not save the data in active order
        vm.continue = function (form) {
            form.$setSubmitted();
            if (!form.$valid) {
                return;
            }
            activeOrder.pickupDetailsModified =
                activeOrder.timeSlot.id != vm.schedule.timeSlotId || !angular.equals(activeOrder.contactDetails, vm.addr);

            activeOrder.timeSlot = _.find(timeSlots, {id: parseInt(vm.schedule.timeSlotId)});
            activeOrder.contactDetails = angular.copy(vm.addr);
            // $ionicHistory.goBack();
            // $ionicHistory.nextViewOptions({ disableBack: true, historyRoot: false});
            $state.go("basket");
        };

        function getNextAvailableDay() {
            return _.find(vm.days, 'available');
        }

        function clearTimeSlots(){
            vm.days = [];
            vm.daySlots = [];
            vm.schedule = {};
        }

    }
})();
