(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', 'Principal','MyLocalStorage'];

    function LoginController ($rootScope,$state, $timeout, Auth, Principal,MyLocalStorage) {
        var vm = this;

        if( MyLocalStorage.getItem('loginScreenVisited') !="true" ){
            MyLocalStorage.setItem('loginScreenVisited',true);
            $state.go('register');
        }

        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;

        $timeout(function (){angular.element('[ng-model="vm.username"]').focus();});

        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
        }

        function login (form) {
            form.$setSubmitted();
            if (!form.$valid) {
                demo.showNotification("Please fill the Username and Password", 'danger', 'top','center');
                return;
            }
            Auth.login({
                username: vm.credentials.username ,
                password: vm.credentials.password,
                rememberMe: true,
                role: 'ROLE_USER, ROLE_ADMIN, ROLE_DEVELOPER, ROLE_MARKETING_AGNECY, ROLE_PICKUP_EXECUTIVE'
            }).then(function () {
                vm.authenticationError = false;
                $rootScope.$broadcast('authenticationSuccess');

                //send new comers to create basket screen
                if( MyLocalStorage.getItem('basketScreenShown') !="true" ){
                    MyLocalStorage.setItem('basketScreenShown',true);
                    $state.go('create-pickup');
                }else{
                    $state.go('home');
                }
            },function (error) {
                    vm.error = "Entered credentials are not valid";
                    demo.showNotification(vm.error, 'danger', 'top','center');
            }).catch(function () {
                vm.error = 'Invalid user credentials.';
                demo.showNotification(vm.error, 'danger', 'top','center');
                vm.authenticationError = true;
            });
        }

        function register () {
            $state.go('register');
        }

        function requestResetPassword () {
            $state.go('requestReset');
        }
    }
})();
