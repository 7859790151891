(function() {
    'use strict';

    angular
        .module('uzedBizApp', [
            'ngStorage',
            'ngSanitize',
            'ngResource',
            'ngAnimate',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            'ngAutocomplete',
            'angular-loading-bar'
        ])
        .run(run);

    run.$inject = ['stateHandler', 'MyLocalStorage', '$q', 'Catalog', 'CurrentUser', '$rootScope', 'Cart', 'Config'];

    function run(stateHandler,MyLocalStorage, $q, Catalog, CurrentUser, $rootScope, Cart, Config) {
        stateHandler.initialize();
        // Timeout is for httpBackend mocks to be setup

        MyLocalStorage.init().then(function () {
            var runCount = MyLocalStorage.getItem('runCount') || "0";
            runCount = parseInt(runCount);
            runCount++;
            MyLocalStorage.setItem('runCount', runCount);

            $q.all({
                config: Config.init(),
                categories: Catalog.getCatalog().$promise,
                cu: CurrentUser.refresh()
            }).then(function (result) {
                if (CurrentUser.activeOrder) {
                    Cart.fill(CurrentUser.activeOrder.orderItems);
                } else {
                    Cart.load();
                }
                if (CurrentUser.currentUser && CurrentUser.currentUser.ratingStatus == 'REQUEST') {
                    //TODO allow Rating prompt
                    // RatingService.promptForRating();
                }
            }).catch(function () {
                $rootScope.initFail = true;
            });

    });

    } //Run end

})();
