(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('uzedBizApp')
        .constant('VERSION', "0.0.1-SNAPSHOT")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('BUILD_TIMESTAMP', 1522654179412)
        .constant('APP_CONSTANTS', {
	"API_BASE_URL": "http://api.biz.uzed.in/biz/",
	"SPURFF_BASE_URL": "http://biz.spurff.com/"
})
;
})();
