(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .factory('Config', ConfigService);

    ConfigService.$inject = ['$resource'];

    /*== ORDER API ==*/
    function ConfigService($resource) {
        var config;
        var service = {
            config: function () {
                return config;
            },
            init: function () {
                config = $resource('api/config').get();
                return config.$promise;
            }
        };
        return service;

    }


})();
