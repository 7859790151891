(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .factory('CurrentUser', CurrentUserService);

    CurrentUserService.$inject = ['ProfileService', '$rootScope', '$q', 'Order','Statement'];

    function CurrentUserService(ProfileService, $rootScope, $q, Order,Statement) {
        var dataStats,dataStatements;

        var service = {
            currentUser: undefined,
            activeOrder: undefined,
            needRefresh: false,
            refresh: refresh,
            refreshUser: refreshUser,
            logout : logout,
            getStats:getStats,
            getStatements:getStatements
        };

        $rootScope.$on("needRefresh", function () {
            console.log("needRefresh Called");
            service.needRefresh = true;
            dataStats = undefined;
            dataStatements = undefined;
        });

        return service;

        function refreshUser() {
            if (service.currentUser && !service.needRefresh) {
                return $q.resolve(service.currentUser);
            }

            service.needRefresh = false;
            return ProfileService.getProfileInfo(true).then(function (user) {
                if (user.picture) {
                    user.picture = user.picture.replace(/sz=50/, 'sz=150');
                    if (user.picture.match(/graph.facebook/)) {
                        user.picture += "?type=large";
                    }
                    // console.log("user.picture = " + JSON.stringify(user.picture));
                }
                // send profile to freshchat
                window.fcSettings = {
                    token: "b2034893-6df5-445a-9bb2-416603c06386",
                    host: "https://wchat.freshchat.com",
                    externalId: user.currentUser.id,     // user’s id unique to your system
                    firstName: user.currentUser.firstName,              // user’s first name
                    lastName: "",                // user’s last name
                    email: user.currentUser.email,    // user’s email address
                    phone: user.currentUser.mobile,
                    properties: {
                        Company: user.currentUser.lastName,                 // meta property 1
                        Credits: user.currentUser.balance
                    },// phone number without country code
                    phoneCountryCode: "+91"          // phone’s country code
                };
                /*window.fcWidget.setExternalId(user.currentUser.id);
                window.fcWidget.user.setFirstName(user.currentUser.name);
                window.fcWidget.user.setEmail(user.currentUser.email);
                window.fcWidget.user.setProperties({
                    Company: user.currentUser.lastName,                 // meta property 1
                    Credits: user.currentUser.balance                // meta property 2
                });*/
                service.currentUser = $rootScope.currentUser = user;
                service.activeOrder = $rootScope.activeOrder = null;
                if (user.lastOrder && user.lastOrder.active) {
                    user.lastOrder.orderItemCount = _.sumBy(user.lastOrder.orderItems, "qty");
                    service.activeOrder = $rootScope.activeOrder = new Order(angular.copy(user.lastOrder));
                }

                $rootScope.$emit("activeOrderRefresh");

                return user;
            }).catch(function (response) {
                if (response.status === 401) {
                    return null;
                }
                return $q.reject(response);
            });
        }

        function refresh() {
            return refreshUser();
        }

        function logout() {
            /*Clear hotline chat history of current user*/
            // $rootScope.chat.clearUserData();
            service.currentUser = $rootScope.currentUser = null;
            service.activeOrder = $rootScope.activeOrder = null;
            $rootScope.data = undefined;
        }

        function getStats(){
            if ( service.needRefresh || angular.isUndefined(dataStats)) {
                dataStats = ProfileService.stats();
                return dataStats;
            }
            return dataStats;
        }

        function  getStatements() {
            if ( service.needRefresh || angular.isUndefined(dataStatements)) {
                dataStatements = Statement.getAll();
                return dataStatements;
            }
            return dataStatements;
        }




    }

})();
