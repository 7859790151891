(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$rootScope','$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'CurrentUser'];

    function NavbarController ($rootScope,$state, Auth, Principal, ProfileService, LoginService, CurrentUser) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        loadProfileInfo();

        function  loadProfileInfo() {
            ProfileService.getProfileInfo().then(function(response) {
                vm.currentUser = response.currentUser;
            });
        }

         $rootScope.$on("needRefresh", function () {
            loadProfileInfo();
         });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            CurrentUser.logout();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
    }
})();
