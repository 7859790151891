(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .controller('FaqModalController', FaqModalController);

    FaqModalController.$inject = ['$scope','$uibModalInstance','questions'];

    function FaqModalController ($scope, $uibModalInstance,questions) {
        $scope.questions= questions;
        $scope.oneAtATime = true;
    }
})();
