(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('forgot-password', {
            // parent: 'app',
            url: '/forgot-password',
            data: {
                authorities: []
            },
            views: {
                'authContent@': {
                    templateUrl: 'app/components/forgot-password/forgot-password.html',
                    controller: 'ForgotPasswordController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
