(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .controller('PickupHistoryController', PickupHistoryController);


    PickupHistoryController.$inject = [ 'Cart', 'Order', 'ErrorExtractor','$rootScope', 'Config','Pickup','ParseLinks','pagingParams','paginationConstants','$state','$uibModal'];

    function PickupHistoryController (Cart, Order, ErrorExtractor, $rootScope, Config,Pickup,ParseLinks,pagingParams,paginationConstants,$state,$uibModal) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.reasons = Config.config().mobileApp.cancellationReasons;
        vm.transition = transition;
        vm.loadAll = loadAll;
        vm.loadAll();

        function loadAll () {
            Pickup.getPickups({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {

                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.pickups = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data);
            }
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

       vm.showDetails = function (pickupOrder) {
            $uibModal.open({
                templateUrl: 'app/pickups/pickup-history/pickup-details-dialog.html',
                controller: 'PickupDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    pickupOrder: function () {
                        return  pickupOrder;
                    }
                }
            }).result.then(function(callDetails) {


            }, function() {
                //logic after closing modal
            });
        };

       vm.cancelOrder = function () {

            vm.isLoading = true;
            var cancelReason = vm.data.selectedReason === 0 ? vm.data.custReason : vm.data.selectedReason;
            Order.cancelPickup({orderId: $rootScope.activeOrder.id, reason: cancelReason}).$promise.then(function (result) {
                vm.isLoading = false;
                Cart.empty();
                Cart.save();
                $rootScope.$emit("needRefresh");
                swal({
                    title: "Pickup Cancelled!",
                    text: "",
                    html:true,
                    type:'success'
                });
                $state.go('home');

            }).catch(function (reason) {
                vm.isLoading = false;
                var error = ErrorExtractor(reason);
                AlertService.error(error.message);

            });

        };

        vm.getPickupStatusColor = function (status) {
           var level = 'label label-default';
            switch(status){
                case 'CANCELLED':
                case 'REJECTED':
                    level= ' label label-danger';
                    break;

                case 'CREATED':
                case 'VERIFIED':
                case 'PICKUP_SCHEDULED':
                    level= 'label label-info';
                    break;
                case 'PICKUP_DENIED':
                case 'AWAITING_CONFIRMATION':
                case 'HOLD':
                    level= 'label label-warning';
                    break;
                case 'ORDER_COMPLETED':
                case 'PICKUP_COMPLETED':
                    level= 'label label-success';
                    break;
            }
            return level;
        };
    }
})();
