(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .factory('Catalog', CatalogService);

    CatalogService.$inject = ['Category'];

    function CatalogService(Category) {
        var categories;
        var items = [];
        var partners, redemptionData,recycleServiceData,userLocationDenied, igproducts,igCallPending;

        return {
            getCatalog: function () {
                categories = Category.find();
                categories.$promise.then(function (categories) {
                    items = _.flatten(_.map(categories, "items"));
                    for (var c = categories.length - 1; c >= 0; c--) {
                        var cat = categories[c];
                        cat.itemNames = _.map(cat.items, 'name').join(", ");
                        for (var i = cat.items.length - 1; i >= 0; i--) {
                            cat.items[i].qty = 0;
                        }
                    }
                });
                return categories;
            },
            categories: function () {
                return categories;
            },
            items: function () {
                return items;
            }

        };
    }

})();
