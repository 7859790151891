(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .controller('ForgotPasswordController', ForgotPasswordController);

    ForgotPasswordController.$inject = ['$rootScope', '$state', '$timeout','ForgotPasswordService','ErrorExtractor'];

    function ForgotPasswordController ($rootScope,$state, $timeout,ForgotPasswordService,ErrorExtractor) {
        var vm = this;
        vm.step="REQUEST_OTP";

        vm.resetPassword = function (form) {
            form.$setSubmitted();

            if (!form.$valid) {
                return;
            }
            ForgotPasswordService.changePassword(vm.user).$promise.then(function () {
                demo.showNotification("Your Password has been changed successfully! Please login with new password.", 'success', 'top','center');
                /*swal({
                    title: "Your Password has been changed successfully! Please login with new password.",
                    text: "",
                    html:true,
                    type:'success'
                });*/
                $state.go('login');
            }).catch(function (response) {
                demo.showNotification(error.message, 'danger', 'top','center');
            });
        };

        /*Verify OTP*/
        vm.verifyOTP = function () {
            ForgotPasswordService.verifyOTP({otp:vm.user.otp, contact : vm.user.contact}).$promise
                .then(function () {
                    demo.showNotification("Otp verification is done.Now you can change your password", 'success', 'top','center');
                    vm.step="RESET_PASSWORD";
                })
                .catch(function (response) {
                    var error = ErrorExtractor(response);
                    demo.showNotification(error.message, 'danger', 'top','center');
                });
        };


        vm.resendOTP = function () {
            ForgotPasswordService.requestOTP(vm.user.contact).$promise.then(function () {
                    startTimer();
                vm.step="VERIFY_OTP";
                    demo.showNotification("Otp sent to your mobile number and Email", 'success', 'top','center');
                })
                .catch(function (response) {
                    var error = ErrorExtractor(response);
                    demo.showNotification(error.message, 'danger', 'top','center');
                });
        };



        function startTimer() {
            var timer;
            vm.enableResend = false;
            var expiryTime = new Date();
            expiryTime.setSeconds(expiryTime.getSeconds() + (60* 1));
            var updateExpiry = function () {
                vm.expiresIn = (expiryTime - new Date().getTime());
                if (vm.expiresIn < 0) {
                    vm.expiresIn = 0;
                    vm.enableResend = true;
                } else {
                    timer = $timeout(updateExpiry, 1000);
                }
            };
            updateExpiry();
        }
        startTimer();



    }
})();
