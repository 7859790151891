(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .factory('ErrorExtractor', ErrorExtractor);

    function ErrorExtractor () {
        var error = {};
        return function (response) {
            //console.log("ErrorExtractor", response);
            if (response.status === 500) {
                error.message = "Problem connecting to server. Please try again.";
                return error;
            }else if (response.headers && response.headers('X-uzedApp-error')) {
                error.message = response.headers('X-uzedApp-error');
                error.key = response.headers('X-uzedApp-errorCode');
                return error;
            } else if (response.data && typeof response.data === "string") {
                error.message = response.data;
                error.key = null;
                return error;
            } else {
                error.message = "Problem connecting to server. Please try again.";
                return error;
            }
        };
    }
})();
