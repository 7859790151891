(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .controller('AppController', AppController);

    AppController.$inject = ['$scope', '$state', '$rootScope', 'Principal', '$http', 'CurrentUser','$uibModal','FAQService'];

    function AppController($scope, $state, $rootScope, Principal, $http, CurrentUser,$uibModal,FAQService){
        $scope.$state = $state;
        $scope.isAuthenticated = Principal.isAuthenticated;
        loadSqlReports();
        refreshAccount();

        $rootScope.$on('authenticationSuccess', function() {
            loadSqlReports();
            $rootScope.$emit("needRefresh");
            refreshAccount();
        });

        function refreshAccount() {
            CurrentUser.refresh().then(function (user) {
                $scope.user = user;
            });
        }
        $scope.clearCache = function() {
            $http.get("api/admin/clear-cache");
        };

        $scope.checkUninstallations = function() {
            $http.get("api/admin/check-uninstallatons");
        };
        function loadSqlReports(){

        }
        $scope.rolesList = rolesList;
        function rolesList(list) {
            var rList = '';
            if (list != null) {
                if (list.length > 0) {
                    for (var i = 0; i < list.length; i++) {
                        if (rList == '') {
                            rList = list[i].name;
                        }else {
                            rList = rList + ',' + list[i].name;
                        }
                    }
                }
            }
            return rList;
        }

        $scope.openFaqModal = function () {
            if(!angular.isUndefined($scope.questions) && $scope.questions.length > 0){
                openModal();
            }else {
                FAQService.getAll().$promise.then(function (res) {
                    $scope.questions = res;
                    openModal();
                });
            }
        };

        function openModal(){
            $uibModal.open({
                templateUrl: 'app/modals/faq/faq-modal.html',
                controller: 'FaqModalController',
                controllerAs: '$scope',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    questions: function () {
                        return $scope.questions;
                    }
                }
            });
        }

    }
})();
