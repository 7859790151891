(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .controller('ShopOnlineController', ShopOnlineController);

    ShopOnlineController.$inject = ['Principal', 'SpurffService','CurrentUser'];

    function ShopOnlineController (Principal, SpurffService,CurrentUser) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
            CurrentUser.refresh().then(init);
        }

        function init() {
            /*fetch token*/
            // SpurffService.visitSpurff(5359);
            SpurffService.getProducts().then(function (products) {
                vm.products = products;
             //  console.log("Spurff Products", products);
            });
        }

        vm.goToSpurff = function (prodID) {
            SpurffService.openURL(prodID);
        }

    }
})();
