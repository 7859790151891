(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .factory('API', APIService)
        .factory('Category', CategoryAPI)
        .factory('Pickup', PickupAPI)
    .factory("Statement", StatementAPI)
    .factory("Notification", NotificationAPI)
        .factory('TimeSlot', TimeslotService)
        .factory('FAQService', FAQService)
        .factory('ForgotPasswordService', ForgotPasswordService)
        .factory('Verification', VerificationService);

    APIService.$inject = ['$resource'];

    function APIService ($resource) {
        var service = $resource('api/account', {}, {
            'get': {
                method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function (response) {
                        // expose response
                        return response;
                    }
                }
            }
        });

        return service;
    }
    CategoryAPI.$inject = ['$resource'];
    /*== CATEGORIES API ==*/
    function CategoryAPI($resource) {
        return $resource("api/catalog", {}, {
            'find': {method: 'GET', isArray: true}
        });
    }


    PickupAPI.$inject = ['$resource'];

    function PickupAPI($resource){
        return $resource('api/customer/pickups',{}, {
            'getPickups': {method: 'GET', isArray: true}
        });
    }

    TimeslotService.$inject = ['$resource'];
    /*== FETCH TIMSLOT BY PINCODE API ==*/
    function TimeslotService($resource) {
        return $resource("api/time-slots/:id", {}, {
            'find': {method: 'GET', isArray: true},
            'listAvailable': {method: 'GET', isArray: true, params: {zip: '@zip', lat: '@lat', lng: '@lng'}}
        });

    }

    StatementAPI.$inject = ['$resource'];
    /*== STATEMENT API ==*/
    function StatementAPI($resource) {
        return   $resource("api/customer/transactions", {}, {
            getAll: {method: "GET", isArray: true}
        });
    }

    NotificationAPI.$inject = ['$resource'];
    /*== NOTIFICATION API ==*/
    function NotificationAPI($resource) {
        return   $resource("api/customer/notifications", {}, {
            'getAll' : {url:'api/customer/notifications', method: 'GET', params: {limit:'@limit', numDays : '@numDays'},  isArray: true}

        });
    }

    VerificationService.$inject = ['$resource'];
    /*== VERIFICATION API ==*/
    function VerificationService($resource) {
        return $resource("", {}, {
            verify: {method: "POST", url: "api/customer/verify-contact-details"}, // verify mobile after authenticated
            verifyMobile: {method: "POST", url: "api/customer/pre-registration/verify-otp"},    // verify mobile before authenticated
            resendCodes: {method: "POST", url: "api/customer/resend-verification-codes"},
            changeMobile: {method: "POST", url: "api/customer/change-mobile"}
        });
    }

    FAQService.$inject = ['$resource'];
    /*== FAQ API ==*/
    function FAQService($resource) {
        return $resource("api/content/faq.json", {}, {
            getAll: {method:"GET", isArray:true, showLoading:false}
        });
    }

    ForgotPasswordService.$inject = ['$resource'];
    /*== PASSOWRD reset API ==*/
    function ForgotPasswordService($resource) {
       return $resource('api/customer/forgot-password', {}, {
           requestOTP: {method:"POST", url: "api/customer/forgot-password/request-otp"},
            verifyOTP: {method: "POST", url: "api/customer/forgot-password/verify-otp"},    // verify OTP to reset password before authenticated
            changePassword: {method:"POST", url:"api/customer/forgot-password/change-password"}
        });
       }
})();
