(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .factory('Cart', CartService);

    CartService.$inject = ['Catalog', 'MyLocalStorage', '$rootScope'];

    function CartService(Catalog, MyLocalStorage, $rootScope) {
        var categories;

        var cart = {
            total: 0,
            orderItemCount: 0,
            orderItems: orderItems,
            cartItems: cartItems,
            igProduct: {},
            empty: empty,
            save: save,
            load: load,
            fill: fill,
            pincode:null,
        };

        $rootScope.$on("activeOrderRefresh", function () {
            if ($rootScope.activeOrder) {
                fill($rootScope.activeOrder.orderItems, $rootScope.activeOrder.gift);
            }

        });

        $rootScope.cart = cart;
        return cart;

        function orderItems() {
            return Catalog.items().filter(function (item) {
                return item.qty > 0;
            });
        }

        function empty() {
            Catalog.items().forEach(function (item) {
                item.qty = 0;
            });
            cart.total = cart.orderItemCount = 0;
            cart.igProduct = {};
        }

        function cartItems() {
            return orderItems().map(function (item) {
                return {itemId: item.id, qty: item.qty};
            });
        }

        function save() {
            var items = cartItems();
            MyLocalStorage.setItem('orderItems', JSON.stringify(items));
        }

        function load() {
            if (MyLocalStorage.getItem('orderItems')) {
                fill(JSON.parse(MyLocalStorage.getItem('orderItems')));
            }
        }

        function fill(orderItems) {
            empty();
            categories = Catalog.categories();
            var items = _.flatten(_.map(categories, "items"));
            orderItems.forEach(function (orderItem) {
                var item = _.find(items, {id: orderItem.itemId});
                if (!item && orderItem.item) {
                    item = angular.copy(orderItem.item);
                    var category = _.find(categories, {name: item.categoryName});
                    if(category)
                        category.items.push(item);
                    Catalog.items().push(item);
                    items.push(item);
                }
                if (!item) return;

                if (orderItem.itemPrice) {
                    item.price = orderItem.itemPrice;
                }
                item.qty = orderItem.qty;
                cart.orderItemCount += item.qty;
                cart.total += Math.ceil(Math.floor((item.qty * item.price)*100.0)/100.0);
            });
            cart.orderItemCount =Math.floor(cart.orderItemCount*10)/10;
        }
    }
})();
