

(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state','CurrentUser','Pickup','$filter'];

    function HomeController ($scope, Principal, LoginService, $state,CurrentUser,Pickup,$filter) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.showCharts = false;

        vm.register = register;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
            CurrentUser.refresh().then(init);
        }

        function init() {
            CurrentUser.getStats().then(function(response) {
                vm.stats = response.data[0];
                if(vm.stats){
                    vm.expiresInStatement = vm.stats.amountExpiredSoon >0 ? vm.stats.amountExpiredSoon+' Expires in '+vm.stats.expiresInDays+' days' :null ;
                    var months = Math.round( vm.stats.expiresInDays/30);
                    if(months>0){
                        vm.expiresInStatement =  vm.stats.amountExpiredSoon +' Expires in '+months+' months';
                    }
                }
                if(response.data.length === 0) {

                    vm.stats = {
                        balance: 0,
                        carbonEverted: 0,
                        totalCreditsEarned: 0,
                        totalPickups: 0,
                        treesSaved: 0,
                        waterSaved: 0
                    }
                }
            });
        }

        function register () {
            $state.go('register');
        }







        vm.orders ={
            options : {
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-1',
                            type: 'linear',
                            display: true,
                            position: 'left'
                        },
                        {
                            id: 'y-axis-2',
                            type: 'linear',
                            display: true,
                            position: 'right'
                        }
                    ]
                }
            }

        };


        vm.onClick = function (points, evt) {
            console.log(points, evt);
        };



        //get pickup orders for data

        Pickup.getPickups({
            page: 0,
            size: 10,
            sort: 'createdDate,desc'
        }, function(data) {

            var completedStatuses = ['AWAITING_CONFIRMATION','PICKUP_COMPLETED','ORDER_COMPLETED'];

            var orderItems = [];
            vm.pickups =  _.filter(data, function(o) {
                if(completedStatuses.indexOf(o.status) > -1){
                    o.pickupTime = $filter('date')(o.pickupTime, "MMM-dd");
                    orderItems = orderItems.concat(o.orderItems);
                    return true;
                }
                return false;
            });
            if(vm.pickups && vm.pickups.length === 0){
                return;
            }

            vm.orders.labels = _.map(vm.pickups, "pickupTime");
            vm.orders.series = ['Total Credits', 'Total Qty'];
            vm.orders.data = _.map(vm.pickups, "totalAmount");


            // _.map(vm.pickups, "totalQty")
            vm.orders.datasetOverride = [{ yAxisID: 'y-axis-1' }, { yAxisID: 'y-axis-2' }];

            //order items
            vm.itemsData = _.groupBy(orderItems,'itemId');

            vm.items={
                labels :[],
                series : ['Pickup Items'],
                data:  []
            };

            _.forEach(vm.itemsData, function(itemInfo) {
                vm.items.labels.push(itemInfo[0].item.name);
                vm.items.data.push(_.sumBy(itemInfo, function(i) { return i.verifyQty;}));
            });

            vm.barChartColors =['#ffff'];

            vm.showCharts = true;

            demo.startRenderingLineChart(vm.orders);
            demo.startRenderingBarChart(vm.items);
        }, function (error) {
            console.log("could not extract pickups");
        });





    }






})();
