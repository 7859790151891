(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .controller('NotificationsController', NotificationsController);


    NotificationsController.$inject = [ '$timeout', 'Auth', 'ProfileService','Notification'];

    function NotificationsController ($timeout, Auth, ProfileService,Notification) {
        var vm = this;
        var params = {
            limit : 20,
            numDays : 200
        };

        Notification.getAll(params).$promise.then(function(response) {
            vm.notifications =  response;
            console.log("Notifications", vm.notifications);
        }).catch(function (error) {
            vm.notifications = [];
        });
    }
})();
