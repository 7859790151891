(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['$rootScope','Principal', 'ProfileService', 'Auth','CurrentUser'];

    function SettingsController ($rootScope,Principal, ProfileService, Auth,CurrentUser) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.save = save;
        vm.settingsAccount = null;
        vm.success = null;

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (profile) {
            return {
                id: profile.id,
                activated: profile.activated,
                email: profile.email,
                name: profile.firstName,
                mobile: profile.mobile,
                lastName: profile.lastName,
                designation: profile.designation
            };
        };

        ProfileService.getProfileInfo().then(function(profile) {
            vm.settingsAccount = copyAccount(profile.currentUser);
        });

        function save () {
            ProfileService.updateProfile(vm.settingsAccount).then(function() {
                vm.error = null;
                vm.success = 'OK';
                ProfileService.getProfileInfo(true).then(function(profile) {
                    vm.settingsAccount = copyAccount(profile.currentUser);
                    demo.showNotification("Profile Updated successfully!!", "success", "top", "center");
                });
                $rootScope.$emit("needRefresh");
            }).catch(function() {
                vm.success = null;
                vm.error = 'ERROR';
                demo.showNotification("Updated failed! Please Try Again!", "danger", "top", "center");
            });
        }
        function changePassword () {
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                demo.showNotification("The password and its confirmation do not match!!", "danger", "top", "center");
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;
                Auth.changePassword({newPassword: vm.password}).then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                    demo.showNotification("Password Updated Successfully", "success", "top", "center");
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                    demo.showNotification("<strong>An error has occurred!</strong> The password could not be changed.", "danger", "top", "center");
                });
            }

        }
    }
})();
