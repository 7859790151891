(function() {
    'use strict';
    angular
        .module('uzedBizApp')
        .controller('StatementHistoryController', StatementHistoryController);


    StatementHistoryController.$inject = ['CurrentUser','$filter'];


    /**
     * Created by roshan
     */
//, StatementService, $filter
    function StatementHistoryController(CurrentUser,$filter) {

        var vm = this;

        vm.filterBy = '';
        vm.tabChanged = function (tabType) {
          vm.filterBy = tabType;
        };

        vm.amount = 200;

        console.log("in statement controller");
        CurrentUser.getStatements().$promise.then(function (txns) {
            vm.txns = [];
          angular.forEach(txns, function (txn) {
            var transaction = vm.getMode(txn);
            vm.txns.push(transaction);
          });
        });

        vm.source = function (txn) {
          if (txn.displayText) return txn.displayText;
          if (txn.pickupOrder) {
            return "UZED";
          } else if (txn.subType == "BONUS") {
            return "Signup Bonus";
          }
          return txn.paymentTxn.businessPartner.name;
        };
        vm.logo = function (txn) {
          return txn.paymentTxn ?
            txn.paymentTxn.businessPartner.logo :
            "ionic.png";
        };
        vm.getMode = function (txn) {
          var mode = {};
          mode.createdDate = txn.createdDate;
          mode.amount = txn.amount;
          mode.type = txn.type;

          if (txn.paymentTxn) {
            if (txn.type === "DEBIT") {
              mode.title = "Purchased at";
              mode.source = txn.displayText;
              mode.caption = !_.isNull(txn.paymentTxn.billNo) ? 'Bill No. #' + txn.paymentTxn.billNo : "" ;
              mode.iconType = "debit";

            } else if (txn.type === "CREDIT") {
              mode.title = "Refunded By";
              mode.source = txn.displayText;
              mode.caption = !_.isNull(txn.paymentTxn.billNo) ? 'Bill No. #' + txn.paymentTxn.billNo : "";
              mode.iconType = "reversed";
            }
            return mode;
          }
          else if (txn.pickupOrder) {
            if (txn.type === "DEBIT") {
              mode.title = "Reversed for";
              mode.source = txn.displayText || "UZED Basket";
              mode.caption = 'Order ID #' + txn.pickupOrder.id;
              mode.iconType = "debit";
            } else if (txn.type === "CREDIT") {
              mode.title = "Received From";
              mode.source = txn.displayText || "UZED Basket";
              mode.caption = !_.isNull(txn.expiresOn) ? (txn.remainingAmount > 0 ? '<i ng-bind-html="Constant.Rs"></i>' + txn.remainingAmount : '') + ' Expires On ' + $filter('date')(txn.expiresOn, 'd MMM yyyy') : "";
              mode.iconType = "credit";
            }
            return mode;
          }
          else if (txn.subType === "BONUS") {
            if (txn.type === "CREDIT"){
              mode.title = "Received from";
              mode.source = txn.displayText;
              mode.caption = !_.isNull(txn.expiresOn) ? (txn.remainingAmount > 0 ? '<i ng-bind-html="Constant.Rs"></i>' + txn.remainingAmount : '') + ' Expires On ' + $filter('date')(txn.expiresOn, 'd MMM yyyy') : "";;
              mode.iconType = "credit";
            }else if(txn.type === "DEBIT"){
              mode.title = "Expired";
              mode.source = txn.displayText;
              mode.caption = "";
              mode.iconType = "debit";
            }
            return mode;

          }else{
            if (txn.type === "CREDIT"){
              mode.title = "Received from";
              mode.source = txn.displayText;
              mode.caption = !_.isNull(txn.expiresOn) ? (txn.remainingAmount > 0 ? '<i ng-bind-html="Constant.Rs"></i>' + txn.remainingAmount : '') + ' Expires On ' + $filter('date')(txn.expiresOn, 'd MMM yyyy') : "";
              mode.iconType = "credit";
            }else if(txn.type === "DEBIT"){
              mode.title = "Debited for";
              mode.source = txn.displayText;
              mode.caption = !_.isNull(txn.expiresOn) ? 'Expires On ' + $filter('date')(txn.expiresOn, 'd MMM yyyy') : "";
              mode.iconType = "debit";
            }
            return mode;
          }
        };
    }
})();
