(function() {
    'use strict';
    angular
        .module('uzedBizApp')
        .directive('uzedLoader', uzedLoader);

    uzedLoader.$inject = [];

    function uzedLoader() {
        return {
            template: '' +
            '<div ng-if="!value" style="display: flex;align-items: center;height: 376px;flex-direction: column;justify-content: center;"  >' +
            '<img class="ld ld-spin"  src="content/images/logo.png" width="60px" />' +
            '<span class="font-x-medium " style="margin-top: 7px" >Loading...</span>' +
            '</div>',
            restrict: 'E',
            replace: true,
            scope: {
                value: '=',
                text: '='
            },
            link: function (scope, element, attrs) {
                console.log(scope.value);
            }
        };
    }
})();
