(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .factory('PickupService', PickupService);

    PickupService.$inject = ['$http', '$resource','Pickup'];

    function PickupService($http, $resource,Pickup) {

        var pickupsPromise;

        var service = {
            getAllPickups : getAllPickups
         };

        return service;

        function getAllPickups(params) {
            if (angular.isUndefined(pickupsPromise)) {
                pickupsPromise =  Pickup.getPickups(params);
                return pickupsPromise;
            }
            return pickupsPromise;
        }


    }
})();
