(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .factory('SpurffService', SpurffService);

    SpurffService.$inject = ['$http', '$window','APP_CONSTANTS','$rootScope'];

    function SpurffService($http, $window,APP_CONSTANTS,$rootScope) {

        var products, spTokenURL;

        var service = {
            needRefresh: false,
            getProducts : getProducts,
            openURL : openURL,
            getSpurffToken : getSpurffToken
        };


        $rootScope.$on("needRefresh", function () {
            service.needRefresh = true;
            products = undefined;
            spTokenURL = undefined;
        });

        return service;

        function getProducts() {
            if (service.needRefresh  || angular.isUndefined(products)) {
                products = $http.get(APP_CONSTANTS.SPURFF_BASE_URL+'index.php?route=api/products/getActiveProducts', {
                    externalUrl: true
                }).then(function(result) {
                    return result.data;
                });
            }
            return products;
        }

        function getSpurffToken() {
            var url = APP_CONSTANTS.SPURFF_BASE_URL+"index.php?route=account/uzedlogin&redirect_to=product/product&product_id=";
            if (service.needRefresh  || angular.isUndefined(spTokenURL)) {
                var data = {
                    baseURL: url
                };
                spTokenURL = $http.post('api/customer/visit-store/18', data).then(function(result) {
                    return result.data.url;
                });
            }
            return spTokenURL;
        }

        function visitSpurff(prodID) {
            openURL(prodID);
        }

        function openURL(prodID) {
            if(prodID){
                service.getSpurffToken().then(function (token) {
                    var finalURL =  _.replace(token, "product/product&product_id=", "product/product&product_id=" + prodID);
                    $window.open(finalURL , '_blank');
                });
            }
        }

    }
})();
