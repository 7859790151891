(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .controller('PickupDetailController', PickupDetailController);

    PickupDetailController.$inject = ['$scope','$uibModalInstance','pickupOrder'];

    function PickupDetailController ($scope, $uibModalInstance,pickupOrder) {
        var vm = this;
        vm.selectedTab='pickup';
        vm.pickupDetails = pickupOrder;
        vm.save = function () {
           // vm.isCalling = true;
             $uibModalInstance.close(vm.callDetails);
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
