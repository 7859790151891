(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .factory('ProfileService', ProfileService);

    ProfileService.$inject = ['$http', '$rootScope', '$q', 'Order'];

    function ProfileService($http, $rootScope, $q, Order) {

        var dataPromise, dataNotifications;

        var service = {
            getProfileInfo : getProfileInfo,
            updateProfile : updateProfile,
            notifications : notifications,
            stats : stats
        };

        return service;

        function getProfileInfo(force) {
            if (force || angular.isUndefined(dataPromise)) {
                dataPromise = $http.get('api/customer/profile').then(function(result) {
                    console.log("Profile", result);

                    if (result.data) {
                        var response = {};
                        response.lastOrder = result.data.lastOrder;
                        response.currentUser = result.data  ;
                        response.ribbonEnv = false;
                        response.inProduction = true;
                        response.swaggerEnabled = false;
                        return response;
                    }
                });
            }
            return dataPromise;
        }

        function stats() {
             return $http.get('api/customer/stats').then(function(result) {
                return result;
            });

        }

        function updateProfile(data) {
            dataPromise =  $http.put('api/customer/update-profile', data).then(function(result) {
                return result.data;
            });

            return dataPromise;
        }

        function notifications() {
            if (angular.isUndefined(dataNotifications)) {
                dataNotifications = $http.get('api/customer/notifications').then(function(result) {
                    return result.data;
                });
            }
            return dataNotifications;
        }

    }
})();
