(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .controller('CreatePickupController', CreatePickupController);


    CreatePickupController.$inject = [ '$location', '$anchorScroll', 'Catalog', '$timeout', 'MyLocalStorage', 'CurrentUser', 'Cart', '$rootScope'];

    function CreatePickupController ($location, $anchorScroll, Catalog, $timeout, MyLocalStorage, CurrentUser, Cart, $rootScope) {
        var vm = this;
        console.log("In CreatePickupController", "");
        vm.activeCatId = 6;
       /* vm.scrollTo = function(id) {
            $location.hash(id);
            $anchorScroll();
        };*/

        function init(categories) {
            $timeout(function () {
                vm.test = true;
                vm.categories = categories;
            },0);

        }

        Catalog.categories().$promise.then(init);

        vm.title = $rootScope.activeOrder ? "Add to basket" : "Create your Basket";
        vm.Cart = Cart;
        vm.imgsrc = "img/product-count-icon.png";
        vm.opencat = "";

        var timer;
        vm.onHold = function (i, action, repeat) {
            timer = $timeout(function () {
                action(i);
                vm.onHold(i, action, repeat + 1);
            }, repeat < 5 ? 100 : 50);
            // $scope[action](i, 1);
        };


        vm.onRelease = function (i) {
            if (timer) {
                $timeout.cancel(timer);
                timer = null;
            }
        };

        vm.incr = function (i) {
            i.qty++;
            Cart.orderItemCount++;
            Cart.total += i.price;
            vm.expanded = true;
            Cart.save();

        };

        vm.decr = function (i) {
            if (i.qty) {
                i.qty--;
                Cart.orderItemCount--;
                Cart.total -= i.price;
                Cart.orderItemCount > 0 ? vm.expanded = true : vm.expanded = false;
                Cart.save();
            }
        };

        /*Expand the continue cart button*/
        vm.expandButton = function (isExpand) {
            $timeout(function () {
                vm.expanded = isExpand;
            }, 0);

        };

    }
})();
