(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .controller('RegisterController', RegisterController);


    RegisterController.$inject = ['$scope','$timeout', 'Auth', 'LoginService','Verification','ErrorExtractor','$state','$rootScope','$uibModal'];

    function RegisterController ($scope,$timeout, Auth, LoginService,Verification,ErrorExtractor,$state,$rootScope,$uibModal) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.login = LoginService.open;
        vm.registerAccount = {};
        vm.success = null;
        vm.agree = true;
        vm.enableResend=false;
        vm.signUpStep = "PRE_SIGNUP";

        $timeout(function (){angular.element('#login').focus();});

        vm.doPreSignUp = function (form) {
            form.$setSubmitted();
            if (!form.$valid) {
                return;
            }
            $timeout.cancel(timer);
           Auth.registerMobile(vm.user.mobile)
                .then(function (response) {
                    var dataOBJ = {
                        mobile : vm.user.mobile,
                        mode:'Mobile'
                    };
                    vm.user.otp =null;
                    vm.signUpStep = 'VERIFICATION';
                    demo.showNotification("Otp sent to your mobile number", 'success', 'top','center');
                    startTimer();
                })
                .catch(function (response) {
                    var errorCode = response.headers('X-uzedApp-errorCode');
                    var errorMsg = response.headers('X-uzedApp-error');
                    switch (errorCode){
                        case "VERIFIED_BUT_NOT_REGISTERED":
                            demo.showNotification("Mobile is verified", 'success', 'top','center');
                            vm.signUpStep = 'REGISTRATION';
                            break;
                        case "INVALID_MOBILE_NUMBER":
                            // mobile number already validated locally
                            break;
                        case "CUSTOMER_ALREADY_EXISTS":
                            demo.showNotification("Customer already exists with this mobile", 'info', 'top','center');
                            break;
                    }

                });
            ga('send', 'event', 'Registration','Click','OTP_Request');

        };

        vm.verifyMobile = function () {
            var data = {};
            data.mobile = vm.user.mobile;
            data.mobileVerificationCode = vm.user.otp;
            Verification.verifyMobile(data).$promise.then(function (response) {
                if (response.mobileVerified) {
                    demo.showNotification("Mobile Number Verified Successfully", 'success', 'top','center');
                    vm.signUpStep = 'REGISTRATION';
                } else {
                    demo.showNotification("Invalid OTP", 'danger', 'top','center');
                }
            }).catch(function (errorRes) {
                var error = ErrorExtractor(errorRes);
                demo.showNotification("Oops! Try Again", 'danger', 'top','center');
            });
            ga('send', 'event', 'Registration','Click','OTP_Complete');
        };



        vm.doUpdate = function (form) {
            form.$setSubmitted();
            if (!form.$valid) {
                demo.showNotification("Please fill all the fields correctly", 'danger', 'top','center');
                return;
            }

            Auth.register(vm.user)
                .then(function onSuccess(data) {
                    Auth.login({
                        username: vm.user.email ,
                        password:  vm.user.password,
                        rememberMe: true,
                        role: 'ROLE_USER, ROLE_ADMIN, ROLE_DEVELOPER, ROLE_MARKETING_AGNECY, ROLE_PICKUP_EXECUTIVE'
                    }).then(function () {
                        vm.authenticationError = false;
                        $rootScope.$broadcast('authenticationSuccess');
                        $state.go('create-pickup');
                    },function (error) {
                        vm.error = "Entered credentials are not valid";
                        demo.showNotification(vm.error, 'danger', 'top','center');
                        return;
                    }).catch(function () {
                        vm.error = 'Invalid user credentials.';
                        demo.showNotification(vm.error, 'danger', 'top','center');
                        vm.authenticationError = true;
                    });

                })
                .catch(function (response) {
                    var error = ErrorExtractor(response);
                    if (error.key == "INVALID_REFERRAL_CODE") {
                        demo.showNotification("Invalid Referral Code!", 'danger', 'top','center');
                    } else {
                        demo.showNotification(error.message, 'danger', 'top','center');

                    }
                });
            ga('send', 'event', 'Registration','Click','Registration complete');

        };



        //RESEND LOGIC
        vm.resend = function () {
            if(!vm.user.mobile){
                vm.signUpStep = "PRE_SIGNUP";
            }
            Auth.registerMobile(vm.user.mobile)
                .then(function () {
                    resetTimer();
                    demo.showNotification("Otp sent to your mobile number", 'success', 'top','center');
                });
        };


        var timer;
        function startTimer() {
            vm.enableResend = false;
            var expiryTime = new Date();
            expiryTime.setSeconds(expiryTime.getSeconds() + (60));
            var updateExpiry = function () {
                vm.expiresIn = (expiryTime - new Date().getTime());
                if (vm.expiresIn < 0) {
                    vm.expiresIn = 0;
                    vm.enableResend = true;
                } else {
                    timer = $timeout(updateExpiry, 1000);
                }
            };
            updateExpiry();
        }
        function resetTimer() {
            $timeout.cancel(timer);
            startTimer();
        }

        //Terms and conditions modal
        vm.showTermsAndConditions = function () {
            $uibModal.open({
                templateUrl: 'app/components/register/terms-and-conditions.html',
                scope:$scope,
                size: 'lg'
            });
        };
    }

})();
