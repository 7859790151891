(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .factory('MyLocalStorage', MyLocalStorageService);

    MyLocalStorageService.$inject = ['$q'];

    function MyLocalStorageService($q) {
        var items = {};
        var keys = ['devOptions', 'authenticationToken', 'runCount', 'walkthroughShown', 'walkthroughShown2', 'orderItems', 'lastLoggedInDetails', 'showChatButtonOnRegister', 'chatBubbleLastPosition', 'expiryDialog', 'affiliateWalkthroughShown', 'rebrandingDialogShown', 'igWelcomeDialogShown', "lastCity"];

        var fetchItem = function (item) {
            console.log("fetching item = " + JSON.stringify(item));
            var deferred = $q.defer();
            deferred.resolve(localStorage.getItem(item));
            return deferred.promise;
        };

        var setItem = function (item, value) {
            console.log("set item = " + JSON.stringify(item));
            items[item] = value;
            localStorage.setItem(item, value);
        };

        var getItem = function (item) {
            return localStorage.getItem(item);
        };

        var removeItem = function (item) {
            delete items[item];
            localStorage.removeItem(item);
        };

        var clearAll = function () {
            return $q.all(_.map(keys, removeItem)).then(function (vals) {
                console.log("vals = " + JSON.stringify(vals));
                return vals;
            });
        };
        var init = function () {
            console.log("MyLocalStorageService.init");

            return $q.all(_.map(keys, fetchItem)).then(function (vals) {
                console.log("vals = " + JSON.stringify(vals));
                return vals;
            });
        };

        var service = {
            fetchItem: fetchItem,
            getItem: getItem,
            setItem: setItem,
            removeItem: removeItem,
            clearAll:clearAll,
            init: init
        };

        return service;
    }
})();
