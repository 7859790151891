(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .factory('authInterceptor', authInterceptor);

    authInterceptor.$inject = ['$rootScope', '$q', '$location', '$localStorage', '$sessionStorage','APP_CONSTANTS'];

    function authInterceptor ($rootScope, $q, $location, $localStorage, $sessionStorage,APP_CONSTANTS) {
        var service = {
            request: request
        };

        return service;

        function request (config) {
            /*jshint camelcase: false */

            if (/^api/.test(config.url)) {
                config.url = APP_CONSTANTS.API_BASE_URL + config.url;
            }

            config.headers = config.headers || {};
            var token = $localStorage.clientAuthenticationToken || $sessionStorage.clientAuthenticationToken;
            if (token && _.isUndefined(config.externalUrl)) {
                config.headers.Authorization = 'Bearer ' + token;
            }
            return config;
        }
    }
})();
