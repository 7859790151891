(function() {
    'use strict';

    angular
        .module('uzedBizApp')
        .controller('BasketController', BasketController);


    BasketController.$inject = ['$scope', 'Config', 'Order', 'Catalog', 'AlertService', 'MyLocalStorage', 'CurrentUser', 'Cart', '$rootScope', '$state', 'ErrorExtractor','$uibModal'];

    function BasketController ($scope,Config, Order, Catalog, AlertService, MyLocalStorage, CurrentUser, Cart, $rootScope, $state, ErrorExtractor,$uibModal) {
        var vm = this;
        console.log("In CreatePickupController", "");
        var activeOrder;
        var itemsModified;
        var pickupDetailsModified;
        vm.isActiveOrder = false;
        vm.reasons = Config.config().mobileApp.cancellationReasons;
        vm.minOrderAmount = Config.config().pickupOrder.minOrder;
        vm.isActiveOrder = CurrentUser.activeOrder ? true : false;
        vm.data = {
            selectedReason: null,
            custReason: null
        };

        Catalog.categories().$promise.then(init);

        vm.editPickup = function () {
            $state.go('checkout')
        };

        vm.openCancelDialog = function () {
            $uibModal.open({
                templateUrl: 'app/pickups/basket/cancel-dialog/cancel-dialog.html',
                scope:$scope,
                size: 'md'
            });
        };

        vm.cancelOrder = function () {
            vm.isLoading = true;
            var cancelReason = vm.data.selectedReason === 0 ? vm.data.custReason : vm.data.selectedReason;

            if(!cancelReason || cancelReason === ""){
                demo.showNotification("Please specify a reason to cancel your pickup", 'danger', 'top','center');
                return;
            }
            Order.cancelPickup({orderId: $rootScope.activeOrder.id, reason: cancelReason}).$promise.then(function (result) {
                vm.isLoading = false;
                Cart.empty();
                Cart.save();
                $rootScope.$emit("needRefresh");
                swal({
                    title: "Pickup Cancelled!",
                    text: "",
                    html:true,
                    type:'success'
                });
                $state.go('home');

            }).catch(function (reason) {
                vm.isLoading = false;
                var error = ErrorExtractor(reason);
                swal({
                    title: "Oops! Something went wrong",
                    text: error.message,
                    html:true,
                    type:'danger'
                });
            });

        };

        vm.refresh = function() {
            $rootScope.$emit("needRefresh");
            CurrentUser.refresh();
        };

        $rootScope.$on("activeOrderRefresh", function () {
            init();
        });

        function init() {
            if (CurrentUser.activeOrder) {
                activeOrder = new Order(CurrentUser.activeOrder);
                if(activeOrder && activeOrder.timeSlot) {
                    pickupDetailsModified = activeOrder.timeSlot.id != CurrentUser.currentUser.lastOrder.timeSlot.id || !angular.equals(activeOrder.contactDetails, CurrentUser.currentUser.lastOrder.contactDetails);
                }
            } else {
                activeOrder = null;
            }

            console.log('Init Active Order', CurrentUser.activeOrder);
            vm.title = activeOrder ? "Upcoming Pickup" : "My Basket";
            vm.Cart = Cart;
            vm.awaitingConfirmation = activeOrder && activeOrder.status === "AWAITING_CONFIRMATION";
            vm.isEditable = !vm.awaitingConfirmation && !(activeOrder && activeOrder.status === "HOLD");
            if (vm.awaitingConfirmation) {
                vm.title = "Pickup Confirmation";
                var orderItems = activeOrder.orderItems.map(function (orderItem) {
                    return {itemId: orderItem.itemId, qty: orderItem.verifyQty};
                });
                Cart.fill(orderItems);
                // Tracker.trackEvent("AwaitingConfirmation");
            }
            vm.orderItems = Cart.orderItems();
            itemsModified = checkItemsModified();
        }

        vm.removeItem = function (index) {
            var item = vm.orderItems[index];
            Cart.orderItemCount -= item.qty;
            Cart.total -= (item.qty * item.price);
            Cart.coupons -= (item.qty * item.coupons);
            item.qty = 0;
            vm.orderItems.splice(index, 1);
            itemsModified = checkItemsModified();
        };

        vm.emptyCart = function () {
            Cart.empty();
            vm.orderItems = [];
        };

        vm.incr = function (i) {
            if (!vm.isEditable) return;
            i.qty++;
            Cart.orderItemCount++;
            Cart.total += i.price;
            itemsModified = checkItemsModified();
        };

        vm.decr = function (i) {
            if (!vm.isEditable) return;
            if (i.qty) {
                i.qty--;
                Cart.orderItemCount--;
                Cart.total -= i.price;
                itemsModified = checkItemsModified();
            }
        };

        function checkItemsModified() {
            if (!activeOrder) return false;
            var cartItems = Cart.cartItems();
            if (cartItems.length != activeOrder.orderItems.length) {
                return true;
            }
            for (var i = cartItems.length - 1; i >= 0; i--) {
                var cartItem = cartItems[i];
                var orderItem = _.find(activeOrder.orderItems, {itemId: cartItem.itemId});
                if (!orderItem || orderItem.qty != cartItem.qty) {
                    return true;
                }
            }
            return false;
        }

        vm.orderModified = function () {
            return activeOrder && (itemsModified || pickupDetailsModified);
        };

        vm.discardChanges = function () {
            vm.refresh();
            AlertService.info('Your Basket has been reset');
            // $state.go("app.home");
        };

        vm.saveOrder = function () {
            swal({
                    title: "",
                    text: "Are you sure?",
                    type: "info",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success btn-md",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    animation: false
                },
                function(){
                    activeOrder.orderItems = Cart.cartItems();
                    activeOrder.gift = Cart.igProduct;
                    activeOrder.$update().then(function () {
                        $rootScope.$emit("needRefresh");
                        CurrentUser.refresh().then(function () {
                            swal({
                                title: "Pickup Updated!",
                                text: "",
                                type:'success'
                            });
                            $state.go("home");
                        });
                    }).catch(function (response) {
                        var error = ErrorExtractor(response);
                        swal({
                            title: "Can't Update!",
                            text: error.message,
                            html:true,
                            type:'error'
                        });
                    });
                });

        };

        vm.confirm = function () {
            activeOrder.$confirmPickup().then(function () {
                Cart.empty();
                $rootScope.$emit("needRefresh");
                swal({
                    title: "Pickup Completed!",
                    text: "",
                    type:'success'
                });
                $state.go("home");
                // Tracker.trackEvent("PickupConfirmed");

            });
        };

        vm.deny = function () {
            activeOrder.$denyPickup().then(function () {
                $rootScope.$emit("needRefresh");
                swal({
                    title: "Pickup Denied!",
                    text: "",
                    type:'error'
                });
                $state.go("home");
                // Tracker.trackEvent("PickupDenied");
            });
        };
    }
})();
